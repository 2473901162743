import React from 'react';
import _ from 'lodash';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { leadershipData } from './partials/teamData';
import emailIcon from '../../images/careers/icons/emailIcon.svg';
import linkedInIcon from '../../images/careers/icons/linkedInIcon.svg';

const renderSection = (data, title) => (
  <div>
    <div className="title-container">
      <p>{title}</p>
      <div className="divider" />
    </div>

    <div className="mtt-photo-container">
      {_.map(data, ({ id, name, role, image, locale, email, linkedIn }) => (
        <div key={id} className="mttp-item">
          <div className="circle">
            <div className="portrait">
              <img src={image} alt="" />
            </div>
            <div className="portrait-silly">
              <img src={image} alt="" />
            </div>
          </div>
          <p className="title">{name}</p>
          <p className="description">
            <FormattedHTMLMessage
              id={`careers.${locale}Role`}
              defaultMessage={role}
            />
          </p>
          <div className="contact">
            <a href={`mailto:${email}`}>
              <img src={emailIcon} alt={`email-${name}`} />
            </a>
            <a href={linkedIn}>
              <img src={linkedInIcon} alt={`linkedIn-${name}`} />
            </a>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const Section4 = () => (
  <div className="section-4">
    <h1 className="top-title">
      <FormattedMessage
        id="careers.meetTheTeam"
        defaultMessage="Meet the Team"
      />
    </h1>
    {renderSection(leadershipData, 'leadership')}
    <a
      href="https://jobs.lever.co/inkblottherapy"
      target="_blank"
      rel="noopener noreferrer"
      className="dark-blue-button"
    >
      <FormattedMessage
        id="about.openPositions"
        defaultMessage="Open Positions"
      />
    </a>
  </div>
);

export default Section4;
