import flexibleHours from '../../../images/careers/animations/flexibleHours.svg';
import greatBenefits from '../../../images/careers/animations/greatBenefits.svg';
import workFromHome from '../../../images/careers/animations/workFromHome.svg';
import unlimitedVacation from '../../../images/careers/animations/unlimitedVacation.svg';

export const perkData = [
  {
    id: 1,
    image: flexibleHours,
    locale: 'FlexibleHours',
    title: 'Flexible Hours',
    text:
      'At Inkblot we understand that life happens. We offer flexible work hours for the times when you need to put your health, family, or wellbeing first.',
  },
  {
    id: 2,
    image: greatBenefits,
    locale: 'GreatBenefits',
    title: 'Great Benefits',
    text:
      'We pride ourselves in offering flexible and top tier benefits covering medical, dental, mental health and more. You’ll also receive benefits for your dependents.',
  },
  {
    id: 3,
    image: workFromHome,
    locale: 'WorkFromHome',
    title: 'Work From Home',
    text:
      'Save yourself the long commute and work from home with a team that values online and offline collaboration.',
  },
  {
    id: 4,
    image: unlimitedVacation,
    locale: 'UnlimitedVacation',
    title: 'Unlimited Vacation',
    text:
      'Yep, you heard that right. Whether it’s remote work, or simply time off to recharge, we want to to feel energized and motivated at work.',
  },
];
