import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { perkData } from './partials/perkData';

const Section3 = () => (
  <div className="section-3">
    <h1>
      <FormattedMessage
        id="careers.sweetPerks"
        defaultMessage="Some sweet perks"
      />
    </h1>
    <div className="perks">
      {perkData.map(perk => {
        const {
          image, title, text, locale,
        } = perk;
        return (
          <div key={locale} className="perk">
            <img src={image} alt={title} />
            <FormattedHTMLMessage
              tagName="h3"
              id={`careers.perkTitle${locale}`}
              defaultMessage={title}
            />
            <FormattedHTMLMessage
              tagName="p"
              id={`careers.perkText${locale}`}
              defaultMessage={text}
            />
          </div>
        );
      })}
    </div>
  </div>
);

export default Section3;
