import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import peopleSlideshow from '../../images/careers/people/people-slideshow.png';
import greatPlaceToWork from '../../images/careers/people/greatplace.png';
import greatPlaceToWorkFr from '../../images/careers/people/greatplacefr.png';
import { getLocale } from '../../utils/locale';

const SectionOne = () => {
  const isFr = getLocale() === 'fr';
  return (
    <div className="section-1">
      <div className="header">
        <div className="image-gptw">
          <img
            src={isFr ? greatPlaceToWorkFr : greatPlaceToWork}
            alt="Great place to work"
          />
        </div>
        <h1>
          <FormattedMessage
            id="careers.careerAndTeam"
            defaultMessage="A career & team you'll love."
          />
        </h1>
        <FormattedHTMLMessage
          tagName="p"
          id="careers.wereLooking"
          defaultMessage="We’re looking for motivated professionals who want to build something awesome and make a difference."
        />
        <a
          href="https://jobs.lever.co/inkblottherapy"
          target="_blank"
          rel="noopener noreferrer"
          className="dark-blue-button"
        >
          <FormattedMessage
            id="about.openPositions"
            defaultMessage="Open Positions"
          />
        </a>
      </div>
      <div className="image-wrapper">
        <img src={peopleSlideshow} alt="People slideshow" />
      </div>
    </div>
  );
};

export default SectionOne;
