import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

const Section5 = () => (
  <div className="section-5">
    <FormattedHTMLMessage
      tagName="h1"
      id="careers.weValueGrowth"
      defaultMessage="At Inkblot <i class='regular'>we value professional growth</i> and want you to reach your full potential."
    />
  </div>
);

Section5.propTypes = {};

export default Section5;
