import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import quote from '../../images/new/animations/orangequote.svg';

const Section2 = () => (
  <div className="section-2">
    <div className="testimonial-section">
      <div className="name-section">
        <img className="quote" src={quote} alt="" />
        <span className="name">Adam Sweeney</span>
        <span className="title">
          <FormattedMessage id="careers.adamRole" />
        </span>
      </div>
      <div className="quote-section">
        <FormattedHTMLMessage
          tagName="p"
          id="careers.workFantastic"
          defaultMessage="<span class='regular'>“Working at Inkblot has been fantastic!</span>"
        />
        <p>
          <FormattedMessage
            id="careers.fantasticTeam"
            defaultMessage="The thing I appreciate the most is that it has given me a great work-life balance by having flexible hours. I also manage a fantastic team and am continually learning and growing"
          />
        </p>
        <p>
          <FormattedMessage
            id="careers.motivator"
            defaultMessage="In addition, Inkblot is a service that provides help to people in need, which has been an excellent motivator and a big reason why I continue to enjoy working here.”"
          />
        </p>
      </div>
    </div>
    <div className="button">
      <a
        href="https://jobs.lever.co/inkblottherapy"
        target="_blank"
        rel="noopener noreferrer"
        className="dark-blue-button"
      >
        <FormattedMessage
          id="about.openPositions"
          defaultMessage="Open Positions"
        />
      </a>
    </div>
  </div>
);

export default Section2;
