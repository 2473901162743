import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import quote from '../../images/new/animations/orangequote.svg';
import emmaQuotePhoto from '../../images/careers/people/emma-quote-photo.png';

const Section7 = () => (
  <div className="section-7">
    <div className="blake-quote">
      <div className="name-section">
        <img className="quote" src={quote} alt="" />
        <span className="name">Emma Esselink, RSW</span>
        <span className="title">
          <FormattedMessage
            id="careers.emmaRole"
            defaultMessage="Practitioner Network Specialist"
          />
        </span>
      </div>
      <div className="quote-section">
        <FormattedHTMLMessage tagName="p" id="careers.differenceComesFirst" />
        <p>
          <FormattedMessage id="careers.wereAllAffected" />
        </p>
        <p>
          <FormattedMessage id="careers.greatOpportunity" />
        </p>
      </div>
      <img
        className="person-photo"
        src={emmaQuotePhoto}
        alt="Making a difference"
      />
    </div>
  </div>
);

Section7.propTypes = {};

export default Section7;
