import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Section1 from '../pagePartials/careers/Section1';
import Section2 from '../pagePartials/careers/Section2';
import Section3 from '../pagePartials/careers/Section3';
import Section4 from '../pagePartials/careers/Section4';
import Section5 from '../pagePartials/careers/Section5';
import Section7 from '../pagePartials/careers/Section7';

const MeetTheTeam = props => (
  <Layout location={props.location}>
    <div className="careers" role="main">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section7 />
    </div>
  </Layout>
);

MeetTheTeam.propTypes = {
  location: PropTypes.object.isRequired,
};

export default MeetTheTeam;
