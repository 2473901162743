import Luke from '../../../images/careers/people/leadership/luke.png';
import Ron from '../../../images/careers/people/leadership/ron.png';
import Denise from '../../../images/careers/people/leadership/denise.jpeg';
import Katherine from '../../../images/careers/people/leadership/katherine.png';
import Yiwen from '../../../images/careers/people/leadership/yiwen.png';
import Joel from '../../../images/careers/people/leadership/joel.jpeg';
import Adam from '../../../images/careers/people/leadership/adam.png';
import Eda from '../../../images/careers/people/leadership/eda.png';
import Jacquie from '../../../images/careers/people/leadership/jacquie.png';
import Mira from '../../../images/careers/people/leadership/mira.png';
import Julia from '../../../images/careers/people/leadership/julia.jpeg';
import Andrew from '../../../images/careers/people/leadership/andrew.png';

export const leadershipData = [
  {
    id: 1,
    name: 'Luke Vigeant',
    locale: 'luke',
    role: 'President',
    image: Luke,
    email: 'luke@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/vigeant/',
  },
  {
    id: 2,
    name: 'Ron Reed',
    locale: 'ron',
    role: 'Chief Financial Officer',
    image: Ron,
    email: 'ron@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/ronreeda/',
  },
  {
    id: 3,
    name: 'Denise Richardson',
    locale: 'denise',
    role: 'Senior VP, Commercial',
    image: Denise,
    email: 'Denise@inkblottherapy.com ',
    linkedIn: 'https://www.linkedin.com/in/denise-richardson-03132525/',
  },
  {
    id: 4,
    name: 'Katherine MacArthur ',
    locale: 'katherine',
    role: 'VP, Operations',
    image: Katherine,
    email: 'katherine@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/katherinemacarthur/',
  },
  {
    id: 6,
    name: 'Yiwen Sim',
    locale: 'yiwen',
    role: 'VP, Product',
    image: Yiwen,
    email: 'yiwen@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/yiwensim/',
  },
  {
    id: 7,
    name: 'Joel Muise',
    locale: 'joel',
    role: 'Senior Director, iCBT',
    image: Joel,
    email: 'joel@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/joelmuise/',
  },
  {
    id: 8,
    name: 'Adam Sweeney',
    locale: 'adam',
    role: 'Senior Director of Engineering',
    image: Adam,
    email: 'adam@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/asweeney75',
  },
  {
    id: 7,
    name: 'Andrew McCartney',
    locale: 'andrew',
    role: 'Senior Director of Client Management',
    image: Andrew,
    email: 'andrew@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/andrew-mccartney-1b436596',
  },
  {
    id: 9,
    name: 'Eda Shere',
    locale: 'eda',
    role: 'Director, Client Management',
    image: Eda,
    email: 'eda@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/eda-shere-2446b312',
  },
  {
    id: 10,
    name: 'Jacquie Wortley',
    locale: 'jacquie',
    role: 'Director of Product Design',
    image: Jacquie,
    email: 'jacquie@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/jacquelinewortley',
  },
  {
    id: 11,
    name: 'Mira Levine',
    locale: 'mira',
    role: 'Director of Partnerships',
    image: Mira,
    email: 'mira@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/mira-levine-b7b6aba4',
  },
  {
    id: 12,
    name: 'Julia Scott',
    locale: 'julia',
    role: 'Director, People & Culture',
    image: Julia,
    email: 'Julia@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/julia-scott-b220bb17/',
  },
];
